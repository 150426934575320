<template>
  <div style="border: 1px solid black;">
    {{place.id}}
    <hr>
    {{place.title}}
    <br>
    {{place.description}}
    <button v-on:click="showMore">Узнать больше</button>
  </div>
</template>

<script>
// import Axios from 'axios';

export default {
  name: 'Place.vue',
  props: {
    place: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showMore() {
      // const { data } = Axios.get('/api/places/1').then(response => response.data);
    },
  },
};
</script>
